import * as t from '../mutations'
import axiosClient from '../../http'

const initialState = () => ({
  jurisdiction: null,
  entityType: null,
  companyName: null,
  entityTypeSuffix: null,
  creatingCompany: false,
  currentSlide: null,
  previousSlides: [],
  similarCompany: null,
  loaded: false,
})

const STATE = initialState()

const GETTERS = {
  jurisdiction: state         => state.jurisdiction,
  entityType: state           => state.entityType,
  companyName: state          => state.companyName,
  entityTypeSuffix: state      => state.entityTypeSuffix,
  creatingCompany: state      => state.creatingCompany,
  currentSlide: state         => state.currentSlide,
  previousSlides: state       => state.previousSlides,
  previousSlidesCount: state  => state.previousSlides.length,
  similarCompany: state       => state.similarCompany,
  loaded: state               => state.loaded,
  fullCompanyName: state      => {
    return state.companyName + (state.entityTypeSuffix ? ' ' + state.entityTypeSuffix : '')
  },
}

const ACTIONS = {
  resetCompanyData({ commit }) {
    commit(t.SET_COMPANY_JURISDICTION, null)
    commit(t.SET_COMPANY_ENTITY_TYPE, null)
    commit(t.SET_COMPANY_NAME, null)
    commit(t.SET_COMPANY_ENTITY_TYPE_SUFFIX, null)
    commit(t.SET_CREATING_COMPANY, false)
  },
  setCompanyJurisdiction({ commit }, jurisdiction) {
    commit(t.SET_COMPANY_JURISDICTION, jurisdiction)
  },
  setCompanyEntityType({ commit }, entityType) {
    commit(t.SET_COMPANY_ENTITY_TYPE, entityType)
  },
  async ensureCompanyNameUniqueness({ getters, rootGetters, commit }) {
    try {
      const params = { company_name: getters.fullCompanyName }
      const response = await axiosClient.get('client/account/ensure_company_name_uniqueness', { params: params })

      if (response?.data?.result?.similar_company_name) {
        const similarCompany = rootGetters['stageline/accountCompanies']
          .find(company => company.name === response.data.result.similar_company_name)
        commit(t.SET_SIMILAR_COMPANY, similarCompany)
      }

      return response
    } catch (error) {
      return error
    }
  },
  async searchCompanyName({ _getters }, { companyName, entityTypeId, jurisdiction, companyId }) {
    const params = {
      company_name: companyName,
      company_id: companyId,
      entityTypeId,
      jurisdiction,
    }
    return await axiosClient.get('client/account/search_company_name', { params: params })
  },
  async createCompany({ dispatch, getters, commit }) {
    try {
      commit(t.SET_CREATING_COMPANY, true)

      const params = { name: getters.fullCompanyName, entity_type_id: getters.entityType.id }
      const response = await axiosClient.post('client/companies', { company: params })

      if (response.data.success) {
        const companyId = response.data.result.id
        await dispatch('companies/setCurrentCompany', { id: companyId, force: false }, { root: true })
        await dispatch('companies/updateDomesticRegistrationJurisdiction', { companyId: companyId, jurisdictionId: getters.jurisdiction.id, loadProducts: false }, { root: true })
      }
      return response.data
    } catch (error) {
      commit(t.SET_CREATING_COMPANY, false)
      return error
    }
  },
  async convertCompany({ dispatch, getters, commit }, companyId = null) {
    try {
      commit(t.SET_CREATING_COMPANY, true)

      const response =
        await axiosClient.post(`client/companies/${companyId}/convert_company`, {
          name: getters.fullCompanyName,
          entity_type_id: getters.entityType.id,
          jurisdiction_id: getters.jurisdiction.id,
        })

      if (response?.data?.success) {
        const companyId = response.data.result.id
        await dispatch('companies/setCurrentCompany', { id: companyId, force: false }, { root: true })
      }
      return response.data
    } catch (error) {
      commit(t.SET_CREATING_COMPANY, false)
      return error
    }
  },
  setCompanyNameAndSuffix({ commit }, { companyName, entityTypeSuffix }) {
    commit(t.SET_COMPANY_NAME, companyName)
    if (entityTypeSuffix) commit(t.SET_COMPANY_ENTITY_TYPE_SUFFIX, entityTypeSuffix)
  },
  setCreatingCompany({ commit }, value) {
    commit(t.SET_CREATING_COMPANY, value)
  },
  resetSlideProgress({ commit }) {
    commit(t.SET_PREVIOUS_SLIDES, [])
    commit(t.SET_CURRENT_CREATE_COMPANY_SLIDE, '')
  },
  setCurrentSlide({ getters, commit }, slideName) {
    if (getters.currentSlide) {
      let previousSlides = getters.previousSlides
      previousSlides.push(getters.currentSlide)
      commit(t.SET_PREVIOUS_SLIDES, previousSlides)
    }

    commit(t.SET_CURRENT_CREATE_COMPANY_SLIDE, slideName)
  },
  setPreviousSlide({ getters, commit }) {
    if (getters.previousSlidesCount > 0) {
      let previousSlides = getters.previousSlides
      const previousSlide = previousSlides.pop()
      commit(t.SET_PREVIOUS_SLIDES, previousSlides)
      commit(t.SET_CURRENT_CREATE_COMPANY_SLIDE, previousSlide)
    }
  },
  setLoaded({ commit }, value) {
    commit(t.SET_CREATE_COMPANY_PAGE_LOADED, value)
  },
}

const MUTATIONS = {
  [t.SET_COMPANY_JURISDICTION](state, jurisdiction) {
    state.jurisdiction = jurisdiction
  },
  [t.SET_COMPANY_ENTITY_TYPE](state, entityType) {
    state.entityType = entityType
  },
  [t.SET_COMPANY_ENTITY_TYPE_SUFFIX](state, entityTypeSuffix) {
    state.entityTypeSuffix = entityTypeSuffix
  },
  [t.SET_COMPANY_NAME] (state, companyName) {
    state.companyName = companyName
  },
  [t.SET_CREATING_COMPANY](state, value) {
    state.creatingCompany = value
  },
  [t.SET_CURRENT_CREATE_COMPANY_SLIDE](state, slide) {
    state.currentSlide = slide
  },
  [t.SET_PREVIOUS_SLIDES](state, slides) {
    state.previousSlides = slides
  },
  [t.SET_CREATE_COMPANY_PAGE_LOADED](state, value) {
    state.loaded = value
  },
  [t.SET_SIMILAR_COMPANY](state, value) {
    state.similarCompany = value
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
